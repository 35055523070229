import React from "react";
import styled from "styled-components";

const SectionContent = styled.section`
  margin: 3.125rem 0;
`;

const Section = (props) => {
    return (
      <SectionContent id={props.id ? props.id : null}>
        {props.children}
      </SectionContent>
    );
};

export default Section;