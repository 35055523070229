import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Container from "../components/Core/Container";
import facepalm from "../assets/images/facepalm.png";
import Section from "../components/Core/Section";
import breakpoints from "../styles/breakpoints";

const Head = styled.div`
  max-width: 18.75rem;
  display: flex;
  flex-direction: row;

  @media only screen and (min-width: ${breakpoints.lg}) {
    max-width: 54.5rem;
  }
`;

const Header = styled.h1`
  font-size: 1.75rem;
  line-height: 3rem;
  margin: 0;
  
  @media only screen and (min-width: ${breakpoints.xs}) {
    min-width: 10rem;
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    font-size: 4rem;
    line-height: 4.75rem;
  }
`;

const Facepalm = styled.div`
  width: 12.5rem;
  height: 12.5rem;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${facepalm});
  margin-left: 1rem;
  margin-top: 1rem;

  @media only screen and (min-width: ${breakpoints.md}) {
    width: 20rem;
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    width: 40rem;
  }
`;

const Sub = styled.p`
  font-size: .875rem;
  font-weight: 700;
  margin-top: 1.25rem;
  margin-bottom: ${props => props.margin && "1.875rem"};
`;

const List = styled.ul`
	list-style: none;
	margin: 1.25rem 0;
	padding: 0;
	
	a {
		font-size: .875rem;
		line-height: 1.6;
		margin-bottom: .625rem;
		display: inline-block;
		color: var(--black);
	}
`;

const LinkStyled = styled(Link)`
  font-size: .875rem;
  color: var(--white);
  background: var(--secondary);
  height: 2.813rem;
  width: 14.375rem;
  max-width: calc(100vw - 2.5rem);
  text-align: center;
  box-sizing: border-box;
  line-height: 2.813rem;
  display: block;
  border-radius: .188rem;
  text-decoration: none;
  transition: .2s;

  &:hover, &:focus {
    background: var(--lightenButton);
    transition: .2s;
  }
`;

export default function ErrorPage() {
	return (
  <Section>
    <Container>
      <Head>
        <Header>Sorry, something went wrong.</Header>
        <Facepalm />
      </Head>
      <Sub>404 - It's us, not you.</Sub>
      <Sub margin={1}>Please check the address again or use one of these handy links:</Sub>
      <List>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/blog">Inspiration</Link></li>
      </List>

      <LinkStyled to="/">Go to Homepage</LinkStyled>
    </Container>
  </Section>
	);
}


export const query = graphql`
	query errorQuery {
		regions: allSanityRegion {
			nodes {
				name
				id
				slug {
					current
				}
			}
		}
		settings: sanitySiteSettings(_id: {eq: "siteSettings"})  {
			description
			id
			keywords
			primaryColor {
				hex
			}
			secondaryColor {
				hex
			}
			title
			logo {
				asset {
					fixed {
						src
					}
				}
			}
			favicon {
				asset {
					fixed(height: 48, width: 48) {
						src
					}
				}
			}
			image {
				asset {
				fixed(height: 600, width: 1200) {
					src
				}
				}
			}
      signUpDisplay
			signUpBackgroundColor
			signUpHeading
			zapierEndpoint
		}
	}
`;